<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Impressionen</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div v-if="loading">
            <div class="loading-wrap">
              <div class="loading-spinner"></div>
            </div>
          </div>
          <div v-else>
            <div class="content-wrap">
              <header class="content-header">
                <h3>Informationen</h3>
              </header>
              <div class="content-body">
                <p style="margin: 0">
                  ID: {{ viewer.device_id }}
                  <span v-if="viewer.name">/ {{ viewer.name }}</span>
                </p>
              </div>
            </div>

            <div class="content-wrap">
              <header class="content-header">
                <h3>Stellen an denen der Kontakt gesehen wurde</h3>
              </header>
              <div class="content-body">
                <ul style="margin: 0; padding-left: 20px">
                  <li v-for="device_viewer in device_viewers" v-bind:key="device_viewer.uuid" style="margin-bottom: 3px">
                    Gesehen am {{ device_viewer.measured_at_formatted }} Uhr an folgendem Display:
                    <router-link :to="'/admin/displays/' + device_viewer.device.uuid" v-if="device_viewer.device" style="color: #444; font-weight: 600">{{
                      device_viewer.device.name
                    }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "admin_viewers",
  components: {
    AdminNav,
  },
  data() {
    return {
      viewer: {},
      device_viewers: [],
      loading: true,
      viewer_count: 0,
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/viewers/" + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.viewer = response.data.viewer;
          this.loading = false;
        });
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/viewers/" + this.$route.params.uuid + "/devices", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.device_viewers = response.data.device_viewers;
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
